var Backbone = require('backbone');

var Book = Backbone.Model.extend({

    

    initialize: function () {

    }

});

module.exports = Book;

